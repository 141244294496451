import React from 'react';
import { graphql } from 'gatsby';
import { Link as RouterLink } from 'gatsby';
import { navigate } from "gatsby"

// import { graphql } from 'react-apollo';
// import gql from 'graphql-tag';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';


import ObjectCard from '../components/ObjectCard';
import Layout from '../components/Layout';
import SEO, {SEOImage} from '../components/SEO';

import PlacesMap from '../components/PlacesMap';



// const CARDS_PER_PAGE = 6;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '44%'
  },
  cityOption: {
    marginLeft: 30
  },
  mapContainer: {
    position: "relative",
    // minHeight: "250px",
    // maxHeight: "500px",
    height: '40vh'
  },
}));

const Places = ({ data, pageContext }) => {
  // if user enters a url combo with no places, send back to main page
  // ** didn't work when building because window not available on server side render
  if (data.gcms.places.length == 0) {
    if (typeof window !== 'undefined') {
      navigate(
        '/places',
        {replace: true}
      );
    }
    
    return null;
  }
  // console.log(location);
  // console.log(data.gcms.availablePlaceTypes);
  const classes = useStyles();

  const { locationSlug, typeSlug } = pageContext;
  const selectedType = typeSlug ? typeSlug : 'all';
  const selectedLocation = locationSlug ? locationSlug : 'all';

  let seoTitleType, seoTitleLocation, seoDescriptionType, seoDescriptionLocation;

  // create type options
  let typeOptions = [<MenuItem key="all" value="all"><em>All</em></MenuItem>];
  {data.gcms.availablePlaceTypes.map(placeType => {
    const typeValue = placeType.slug;
    typeOptions.push(<MenuItem key={typeValue} value={typeValue}>{placeType.namePlural}</MenuItem>);
    if(typeValue == selectedType) {
      seoTitleType = placeType.namePlural;
      seoDescriptionType = placeType.namePlural.toLowerCase();
    }
  })}

  // create location options
  let locationOptions = [<MenuItem value="all"><em>All</em></MenuItem>];
  data.gcms.availableStates.map(state => {
    locationOptions.push(<Divider />);
    const stateValue = 'united_states/' + state.slug;
    locationOptions.push(<MenuItem value={stateValue}>{state.name}</MenuItem>);
    if(stateValue == selectedLocation) {
      seoTitleLocation = state.name;
      seoDescriptionLocation = state.name;
    }
     state.cities.map(city => {
      const cityValue = stateValue + '/' + city.slug;
      locationOptions.push(<MenuItem value={cityValue} className={classes.cityOption}>{city.name}</MenuItem>)
      if(cityValue == selectedLocation) {
        seoTitleLocation = city.name + ', ' + state.name;
        seoDescriptionLocation = city.name + ', ' + state.name;
      }
    })
  })
  data.gcms.availableCountries.map(country => {
    locationOptions.push(<Divider />);
    const countryValue = country.slug;
    locationOptions.push(<MenuItem value={countryValue}>{country.name}</MenuItem>);
    if(countryValue == selectedLocation) {
      seoTitleLocation = country.name;
      seoDescriptionLocation = country.name;
    }
    country.states.map(state => {
      const stateValue = countryValue + '/' + state.slug;
      state.cities.forEach(city => {
        const cityValue = stateValue + '/' + city.slug;
        locationOptions.push(<MenuItem value={cityValue} className={classes.cityOption}>{city.name}</MenuItem>);
        if(cityValue == selectedLocation) {
          seoTitleLocation = city.name + ', ' + country.name;
          seoDescriptionLocation = city.name + ', ' + country.name;
        }
      });
    })
})

  // generate seo data based on selections
  let seoTitle = seoTitleType ? 'Haunted ' + seoTitleType : 'Haunted Places';
  if (seoTitleLocation) {
    seoTitle += ' in ' + seoTitleLocation;
  }
  let seoDescription = seoDescriptionType ? 'Spooky and haunted ' + seoDescriptionType : 'Spooky and haunted locations';
  if (seoDescriptionLocation) {
    seoDescription += ' in ' + seoDescriptionLocation;
  }
  seoDescription += '.';

  // if anything selected use first item from list (if items in list) for image instead of default
  const seoImage = ((typeSlug || locationSlug) && data.gcms.places.length > 0) ? SEOImage(data.gcms.places[0].gallery[0].handle) : null;


  const handleTypeChange = (event) => {
    const newSelectedType = event.target.value;
    let newPath = '/places';
    newPath +=  selectedLocation == 'all' ? '' : `/${selectedLocation}`;
    newPath +=  newSelectedType == 'all' ? '' : `/${newSelectedType}`;
    navigate(newPath);
  };

  const handleLocationChange = (event) => {
    const newSelectedLocation = event.target.value;
    let newPath = '/places';
    newPath +=  newSelectedLocation == 'all' ? '' : `/${newSelectedLocation}`;
    newPath +=  selectedType == 'all' ? '' : `/${selectedType}`;
    navigate(newPath);
  };

  return (

      <Layout>

        <SEO 
          title={seoTitle}
          description={seoDescription}
          image={seoImage}
        />

        <Typography variant="h2">
          Places
        </Typography>

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="place-location-label">Location</InputLabel>
          <Select
            labelId="place-location-label"
            id="place-location"
            value={selectedLocation}
            onChange={handleLocationChange}
            label="Location"
            children={locationOptions}
          />          
        </FormControl>

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="place-type-label">Type</InputLabel>
          <Select
            labelId="place-type-label"
            id="place-type"
            value={selectedType}
            onChange={handleTypeChange}
            label="Type"
            children={typeOptions}
          />
        </FormControl>

        <div className={classes.mapContainer}>
          <PlacesMap places={data.gcms.places} />
        </div>

        <section>
          <Grid container spacing={2}>
            {data.gcms.places.map(place => (
              <ObjectCard
                key={place.id}
                title={place.name}
                subtitle={place.city.state.country.name == "United States" ? `${place.city.name}, ${place.city.state.name} - ${place.placeType.name}` : `${place.city.name}, ${place.city.state.country.name} - ${place.placeType.name}`}
                link={`/place/${place.slug}`}
                image={place.gallery[0]}
                relatedPlaceCount={0}
                relatedBookCount={place.books.length}
                relatedInvestigationCount={place.investigations.length}
                relatedTourCompanyCount={place.tourCompanies.length}
                relatedLoreCount={place.lores.length}
                relatedPersonCount={place.people.length}
              />
            ))}
          </Grid>

          {/* <Grid container justify="center" style={{marginTop: '16px'}}>
                <Grid item >
                  {areMorePosts ? (
                    <Button 
                      variant="contained" 
                      color="primary"
                      disabled={loading}
                      onClick={() => loadMoreCards()}
                    >
                      {loading ? 'Loading...' : 'Show More Places'}
                    </Button>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid> */}
        </section>
      </Layout>
    
  );
};

export const query = graphql`
  query places ($placesCondition: GCMS_PlaceWhereInput = {}, $typeCondition: GCMS_PlaceWhereInput = {}, $locationCondition: GCMS_PlaceWhereInput = {}) {
    gcms {
      places(orderBy: publishedAt_DESC, where: $placesCondition) {
        id
        name
        slug
        gallery (first: 1) {
          handle
          title
        }
        location {
          latitude
          longitude
        }
        city {
          name
          state {
            name
            country {
              name
            }
          }
        }
        placeType {
          name
        }
        books {id}
        investigations {id}
        tourCompanies {id}
        lores {id}
        people {id}
      }
      placesConnection (where: $typeCondition) {
        aggregate {
          count
        }
      }
      availablePlaceTypes: placeTypes(orderBy: namePlural_ASC, where: {places_some: $locationCondition}) {
        namePlural
        slug
      }
      availableCountries: countries(where: {slug_not: "united_states", states_some: {cities_some: {places_some: $typeCondition}}}, orderBy: name_ASC) {
        name
        slug
        states {
          slug
          cities(where: {places_some: $typeCondition}, orderBy: name_ASC) {
            name
            slug
          }
        }
      }
      availableStates: states(where: {country: {slug: "united_states"}, cities_some: {places_some: $typeCondition}}, orderBy: name_ASC) {
        name
        slug
        cities(where: {places_some: $typeCondition}, orderBy: name_ASC) {
          name
          slug
        }
      }
    }
  }
`;

export default Places;