import React, { Component} from 'react';
import {Map, GoogleApiWrapper, InfoWindow, Marker} from 'google-maps-react';

import { Link as RouterLink } from 'gatsby';


import {styles} from '../google-maps-styles';

class PlacesMap extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  // static defaultProps = {
  //   center: {
  //     lat: 59.95,
  //     lng: 30.33
  //   },
  //   zoom: 11
  // };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  centerMap(mapProps, map) {
    map.fitBounds(mapProps.bounds);
    map.panToBounds(mapProps.bounds);

    // lets have a min zoom level (10 = city)
    if (map.getZoom() > 11) {
      map.setZoom(11);
    }

  }

  render() {
    //const classes = useStyles();
    const places = this.props.places;
    //console.log(places);
    let bounds  = new google.maps.LatLngBounds();
    places.map(place => {
      const loc = new google.maps.LatLng(place.location.latitude, place.location.longitude);
      bounds.extend(loc);
    })

    //console.log(bounds);


    return (
      <Map
        google={this.props.google}
        mapTypeControl={false}
        styles={styles}
        bounds={bounds}
        onReady={this.centerMap}
        // defaultCenter={bounds.getCenter()}
        // defaultZoom={this.props.zoom}
        // initialCenter={bounds.getCenter()}
        // center={bounds.getCenter()}
      >
        {places.map(place => (
          <Marker
            key={place.id}
            onClick={this.onMarkerClick}
            position={{ lat: place.location.latitude, lng: place.location.longitude }}
            // icon={{url: 'https://maps.google.com/mapfiles/ms/icons/ltblue-dot.png'}}
            icon={{url: 'https://maps.google.com/mapfiles/ms/icons/purple-dot.png'}}
            name={place.name}
            slug={place.slug}
            imageHandle={place.gallery[0].handle}
          />
        ))}
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
          maxWidth={220}
        >

          <div>
            
            
            <RouterLink to={`/place/${this.state.selectedPlace.slug}`} style={{textDecoration: 'none'}}>
              <img 
                src={`https://media.graphcms.com/resize=w:180,h:60,fit:crop,align:faces/monochrome/${this.state.selectedPlace.imageHandle}`} />
              <h4 style={{color: '#000', fontSize: '1em', margin: '0.2em'}}>{this.state.selectedPlace.name}</h4>
            </RouterLink>
          </div>
          
          
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  // apiKey: (process.env.REACT_APP_GOOGLE_API_KEY)
  // apiKey: (process.env.GATSBY_GOOGLE_API_KEY)
  apiKey: ("AIzaSyAWxrT3f-MaZMCDb1D_2EcuvM7OYcteqDQ")
})(PlacesMap)
